import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import style from './FormInput.module.css';

const FormSearchInput = forwardRef(
  ({ onChange, value, status, disabled, readOnly, ...props }, ref) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    return (
      <div className={cx('flex items-center rounded-md', style.base, style[state])}>
        <div className={cx('shrink-0 pl-3', style.statusColor)}>
          <FontAwesomeIcon icon={faSearch} className="text-lg" fixedWidth />
        </div>
        <input
          value={value}
          className={cx('placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent')}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          {...props}
        />
        {value && (
          <button
            type="button"
            // We use the mousedown event here to prevent changing browser
            // focus from the input to the button
            onMouseDown={(e) => {
              e.preventDefault();
              onChange('');
            }}
            className="shrink-0 pr-3 transition-colors duration-200 hover:text-gray-500"
          >
            <FontAwesomeIcon icon={faTimes} className="text-lg" fixedWidth />
          </button>
        )}
      </div>
    );
  }
);

FormSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FormSearchInput.defaultProps = {
  status: 'default',
  value: '',
  disabled: false,
  readOnly: false,
};

export default FormSearchInput;
